










































import { Component, Vue } from 'vue-property-decorator';
import { CommonGetters } from '@/store/modules/common/enums';
import AuthPanel from '@/views/auth/AuthPanel.vue';
import BlogPostsContainer from '@/views/blog/BlogPostsContainer.vue';
import BlogPostsLatest from '@/views/blog/BlogPostsLatest.vue';
import MyKeyBanner from '@/components/layout/MyKeyBanner.vue';
import { addBackToTop } from 'vanilla-back-to-top';

@Component({
  components: {
    'ck-auth-panel': AuthPanel,
    'ck-my-key-banner': MyKeyBanner,
    'ck-blog-posts-container': BlogPostsContainer,
    'ck-blog-posts-latest': BlogPostsLatest,
  },
})
export default class Main extends Vue {
  mounted(): void {
    if (!document.querySelectorAll('body > #back-to-top').length)
      addBackToTop();
  }

  public get language(): string {
    return this.$store.getters[CommonGetters.Language] ?? 'en';
  }
}
