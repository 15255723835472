









import { Component, Vue } from 'vue-property-decorator';
import { MediasActions } from '@/store/modules/medias/enums';
import { MerchantConfigActions } from '@/store/modules/merchant-config/enums';
import { MerchantMigrationActions } from '@/store/modules/merchant-migration/enums';
import { Routes } from '@/router/routes';
import { WebPaymentsActions } from '@/store/modules/web-payments/enums';
import Footer from '@/components/layout/Footer.vue';
import Header from '@/components/layout/Header.vue';
import HeaderBackground from '@/components/layout/HeaderBackground.vue';
import Main from '@/components/layout/Main.vue';
import { isNotNullOrEmpty } from '@glitchedmob/isnullorempty';
import { msalPluginInstance } from '@/plugin/MsalPlugin';
import { QrAction, urlQueryService } from '@/services/common/urlquery.service';
import { eventHub } from '@/store/consts';
import { QRActions } from '@/models/common/constants';

@Component({
  components: {
    'ck-header': Header,
    'ck-header-background': HeaderBackground,
    'ck-main': Main,
    'ck-footer': Footer,
  },
})
export default class Home extends Vue {
  protected redirecting = false;

  async created(): Promise<void> {
    eventHub.$on('authenticated', this.redirect);
  }

  async mounted(): Promise<void> {
    await this.redirect();
  }

  public get b2cLoading(): boolean {
    return msalPluginInstance.isLoading;
  }

  public async redirect(): Promise<void> {
    this.redirecting = true;
    const logged = msalPluginInstance.isUserAuthenticated();
    const mobileAppRegistrationAction =
      urlQueryService.getMobileAppRegistrationQuery();
    const qrAction = urlQueryService.getQRCodeQuery();
    const nexiAction = urlQueryService.getNexiCheckoutQuery();
    // if receive parameter ma, redirect to mobile app registration
    if (isNotNullOrEmpty(mobileAppRegistrationAction?.hash)) {
      await this.$router.push({
        name: Routes.MobileAppRegistration,
        query: { ma: mobileAppRegistrationAction?.hash },
      });
    }
    // if have some action in qrcheck i move to this page
    else if (
      isNotNullOrEmpty(qrAction?.action) &&
      qrAction?.action == QRActions.QrCheck
    ) {
      await this.$router.push({
        name: Routes.QRCheck,
        query: { id: qrAction.hash },
      });
    } else if (!logged) {
      this.$store.dispatch(MerchantConfigActions.Clear);
      this.$store.dispatch(MediasActions.Clear);
      this.$store.dispatch(WebPaymentsActions.Clear);
      this.$store.dispatch(MerchantMigrationActions.Clear);
    } else if (logged) {
      // console.log('home redirect Customer');
      this.$store.dispatch(MediasActions.SetMediaStatusToAssociate, null);

      await this.$router.push({
        name: Routes.Customer,
        params: {
          type: qrAction?.mediaType as string,
          deviceId: qrAction?.deviceId as string,
          action: qrAction?.action as string,
          serial: qrAction?.mediaSerial as string,
          checkout: nexiAction?.checkout as string,
          checkoutMsg: nexiAction?.checkoutMsg as string,
        },
      });
    }
    this.redirecting = false;
  }

  private hasActionParameter(qrAction: QrAction | undefined): boolean {
    if (!qrAction) {
      return false;
    }
    return qrAction?.action === QRActions.Recharge;
  }
}
